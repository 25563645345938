import { graphql, useStaticQuery } from 'gatsby';
import MobileDevelopmentIcon from 'svgs/single-case-study/mobile-development.svg';
import NodeIcon from 'svgs/single-case-study/node-js.svg';
import SocialIcon from 'svgs/single-case-study/social-media-integration.svg';
import UxUiIcon from 'svgs/single-case-study/ux-ui-design.svg';
import VueJsIcon from 'svgs/single-case-study/vue-js.svg';
import WebDevelopmentIcon from 'svgs/single-case-study/web-development.svg';

import { contentResolver } from '../utilities';

const content = contentResolver({
    project: 'exfluential',
    brandColor: '--exfluential-case-study',
    brandColorGradient: {
        start: '--exfluential-case-study',
        stop: '--exfluential-case-study',
    },
    scopeOfWork: [
        {
            title: 'scopeOfWork.webDevelopment',
            Icon: WebDevelopmentIcon,
        },
        {
            title: 'scopeOfWork.mobileDevelopment',
            Icon: MobileDevelopmentIcon,
        },
        {
            title: 'scopeOfWork.nodeJs',
            Icon: NodeIcon,
        },
        {
            title: 'scopeOfWork.vueJs',
            Icon: VueJsIcon,
        },
        {
            title: 'scopeOfWork.uxUiDesign',
            Icon: UxUiIcon,
        },
        {
            title: 'scopeOfWork.socialMediaIntegration',
            Icon: SocialIcon,
        },
    ],
    projectInfo: {
        count: 2,
        dataIndexes: [0, 2],
        colors: ['--exfluential-case-study', '--exfluential-case-study'],
    },
    paragraphsCounters: {
        header: 1,
        about: 2,
        challenge: 1,
        mainGoals: 1,
        solutions: 1,
        summary: 1,
    },
    aseStudies: ['pregnabit', 'salesPredictiveAlgorithm', 'churn'],
    caseStudiesSubtitle: 'case-studies.subtitle',
});

export const useExfluential = () => {
    const images = useStaticQuery(
        graphql`
            query {
                headerImage: file(
                    relativePath: {
                        eq: "single-case-study/exfluential/laptop.png"
                    }
                ) {
                    ...ImageCaseStudyConfig
                }
                aboutImage: file(
                    relativePath: {
                        eq: "single-case-study/exfluential/about.png"
                    }
                ) {
                    ...ImageCaseStudyConfig
                }
                challengeImage: file(
                    relativePath: {
                        eq: "single-case-study/exfluential/challenge.png"
                    }
                ) {
                    ...ImageCaseStudyConfig
                }
                mainGoalsImage: file(
                    relativePath: {
                        eq: "single-case-study/exfluential/main-goals.png"
                    }
                ) {
                    ...ImageCaseStudyConfig
                }
                solutionsImage: file(
                    relativePath: {
                        eq: "single-case-study/exfluential/solutions.png"
                    }
                ) {
                    ...ImageCaseStudyConfig
                }
                summaryImage: file(
                    relativePath: {
                        eq: "single-case-study/exfluential/summary.png"
                    }
                ) {
                    ...ImageCaseStudyConfig
                }
            }
        `,
    );

    return {
        data: { ...images, ...content },
    };
};
